import request from '@/utils/request';

export function materialSorts() {
  return request({
    url: `/v1/wms/material/sorts`,
    method: 'GET',
  })
}

export function materialSortUpdate(data) {
  return request({
    url: `/v1/wms/material/sortUpdate`,
    method: 'POST',
    data
  })
}

export function materialSortCreate(data) {
  return request({
    url: `/v1/wms/material/sortCreate`,
    method: 'POST',
    data
  })
}

export function materialMates(params) {
  return request({
    url: `/v1/wms/material/mates`,
    method: 'GET',
    params
  })
}

export function materialUpdate(data) {
  return request({
    url: `/v1/wms/material/update`,
    method: 'POST',
    data
  })
}

export function materialCreate(data) {
  return request({
    url: `/v1/wms/material/create`,
    method: 'POST',
    data
  })
}

export function materialDetail(params) {
  return request({
    url: `/v1/wms/material/detail`,
    method: 'GET',
    params
  })
}



