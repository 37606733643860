<template>
  <div class="detail">
    <el-row class="detail-title-container">
      <el-col :span="18">
        <div class="container">
          <p class="container-name">
            <span class="container-name-text">{{material.name}}</span>
          </p>
        </div>
        <div class="container">
          <el-button type="text" class="container-btn">
            <i class="el-icon-edit"/>
            编辑物料
          </el-button>
          <el-button type="text" class="container-btn">
            <i class="iconfont icon-ruku" />
            物料入库
          </el-button>

          <el-button type="text" class="container-btn">
            <i class="el-icon-delete"/>
            删除物料
          </el-button>

          <el-button class="container-back container-btn" type="text" @click="$router.back()">
            <i class="iconfont icon-fanhui"/>
            返回物料仓
          </el-button>
        </div>

      </el-col>

      <el-col :span="6" class="tip-container">
        <img class="tip" src="../../../assets/img/detail.png">
      </el-col>
    </el-row>
    <div class="detail-container">
      <div class="detail-container-item">
        <el-row>
          <el-col :span="8" v-for="(item, idx) in items" :key="idx" class="title">
            {{item.name}}
          </el-col>
          <el-col :span="8" class="item" v-for="(item, _idx) in items" :key="_idx">
            <div v-for="(i, idx) in item.items" :key="idx" class="item-container">
              <span class="item-name">{{i.name}}</span>
              <span class="item-value">{{material[i.engName]}}</span>
            </div>
          </el-col>
        </el-row>
        <el-tabs class="detail-container-table">
          <el-tab-pane key="1" label="入库批次">
            <detail-warehouse></detail-warehouse>
          </el-tab-pane>
          <el-tab-pane key="2" label="耗损记录">
            <detail-use></detail-use>
          </el-tab-pane>
          <el-tab-pane key="3" label="预警历史">
            <detail-alarm></detail-alarm>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
  import DetailWarehouse from './detail_warehouse'
  import DetailAlarm from './detail_alarm'
  import DetailUse from './detail_use'
  import {
    materialDetail
  } from '@/api/org/material'

  export default {
    name: '',
    components: {
      DetailWarehouse,
      DetailAlarm,
      DetailUse,
    },
    mounted: function () {
      if (this.$btnCheck('wms_mate')) {
        materialDetail({uuid: this.$route.params.uuid}).then(res => {
          let data = res.data.info
          data.materialSortName = res.data.sortInfo.name
          data.materialSortUnit = res.data.sortInfo.unit
          if (data.total) {
            data.wasteRate = (data.waste * 100 / data.total) + '%'
          } else {
            data.wasteRate = 0
          }
          if (data.lastAlarmAt) {
            data.lastAlarmAt = new Date(data.lastAlarmAt * 1000).Format('yyyy-mm-dd hh:MM:ss')
          } else {
            data.lastAlarmAt = '-'
          }


          this.material = data
        })
      } else {
        this.$router.go(-1)
      }

    },
    data: function () {
      return {
        material: {},
        items: [
          {
            name: '基础信息',
            items: [
              {
                name: '物料名称',
                engName: 'name',
              },
              {
                name: '物料分类',
                engName: 'materialSortName',
              },
              {
                name: '物料单位',
                engName: 'materialSortUnit',
              },
            ]
          },
          {
            name: '物料用量',
            items: [
              {
                name: '剩余用量',
                engName: 'rest',
              },
              {
                name: '累计用量',
                engName: 'total',
              },
              {
                name: '耗损用量',
                engName: 'waste',
              },
              {
                name: '物料耗损比',
                engName: 'wasteRate',
              },
            ]
          },
          {
            name: '预警信息',
            items: [
              {
                name: '预警阀值',
                engName: 'alarm',
              },
              {
                name: '预警次数',
                engName: 'alarmTime',
              },
              {
                name: '最近预警时间',
                engName: 'lastAlarmAt',
              },
            ]
          }
        ]
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .detail {
    height: 100%;
    width: 100%;
    overflow-y: scroll;

    &-title-container {
      padding-left: $container-padding;
      padding-right: $container-padding;
      padding-top: $middle-space;
      background-color: $color-white;

      .tip-container {
        text-align: right;

        .tip {
          width: 124px;
        }
      }


      .remark {
        margin: $small-space 0;
        @include font-little();
        color: $gray-7;
      }

      .container {
        display: flex;
        justify-items: center;

        &-name {
          &-text {
            @include  font-large-s();
          }

          &-unit {
            display: inline-block;
            margin: 0 $small-space;
            @include font-little();
          }
        }

        &-btn {
          color: $color-base;
          padding-left: 0;
        }

        &-btn:hover {
          color: $theme-color;
          font-weight: 500;
        }

        &-back {
          padding-left: $small-space;

        }
      }

    }

    &-container {
      padding: $middle-space;
      &-table {
        margin-top: $large-space;
      }

      &-item {
        background-color: $color-white;
        padding: $container-padding;
        //width: 80%;
        min-width: 640px;
        margin: auto;
        min-height: 592px;


        .title {
          background-color: $gray-5;
          text-align: center;
          border-right: 1px solid $content-border-color;
          padding: 4px $small-space;
        }

        .title:last-child {
          border: none;
        }

        .item {
          &-container {

          }

          span {
            display: inline-block;
            text-align: center;


          }

          &-name {
            width: 40%;
            background-color: $gray-3;
            @include font-little();
            height: 32px;
            line-height: 32px;
          }

          &-value {
            width: 60%;
            color: $color-black;
            height: 32px;
            line-height: 32px;
            // border-bottom: 1px solid @item-border-color;
            // border-top: none;
          }


        }
      }
    }
  }
</style>
