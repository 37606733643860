<template>
  <a-table
    :columns="columns"
    :row-key="record => record.uuid"
    :data-source="items"
    :pagination="pagination"
    :loading="loading"

    :scroll="{ x: '100%'}"
  >
    <template slot="storeAt" slot-scope="device">{{new Date(device.storeAt * 1000).Format('yyyy-MM-dd')}}</template>
    <template slot="oldDayAt" slot-scope="device">{{new Date(device.oldDayAt * 1000).Format('yyyy-MM-dd')}}</template>
    <template slot="dateLost" ></template>
    <p slot="more">
      <a-button type="link" size="small">设备订单</a-button>
      <a-button type="link" size="small">维修工单</a-button>
      <a-button type="link" size="small">更多详情</a-button>
    </p>
  </a-table>
</template>

<script>
    export default {
        name: 'material-detail-warehouse',
        components: {},
        mounted: function () {
        },
        data: function () {
            return {
              items:[],
              loading:false,
              columns: [
                {
                  title: '批次编号',
                  dataIndex: 'uuid',
                },
                {
                  title: '入库时间',
                  scopedSlots: {customRender: 'storeAt'},
                },
                {
                  title: '报损时间',
                  scopedSlots: {customRender: 'oldDayAt'},
                },
                {
                  title: '报损人',
                  scopedSlots: {customRender: 'dateLost'},
                },
                {
                  title: '报损用量',
                  dataIndex: 'waste',
                },
              ],
              pagination: {
                start: 0,
                curPage: 1,
                limit: 10,
                total: 1,
              },
            }
        },
        methods: {},
    }
</script>

<style>

</style>
